import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./styles.css";
import { createClient } from "@supabase/supabase-js";
const dayjs = require("dayjs");
// Create a single supabase client for interacting with your database
const supabase = createClient(
  "https://lvykkopdvnxbqupaivhe.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx2eWtrb3Bkdm54YnF1cGFpdmhlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDg5ODMzMjIsImV4cCI6MjAyNDU1OTMyMn0.7Y7CC_FwB58ETbhA6jDszC70oxTm0O10ANg8O8vge4o"
);
/*console.log(await supabase.from("partners").select("*"));
console.log(await supabase.from("partner_doctors").select("*"));*/
registerCoreBlocks();
const App = () => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          messages: {
            "label.button.ok": "Suivant", // Default: 'Ok'
            "label.hintText.enter": "appuyez sur <strong>Entrer ↵</strong>", // Default: "",
            "label.hintText.multipleSelection":
              "Choisissez autant que nécessaire", // Default: "Choose as many as you like"
            "block.dropdown.placeholder": "Sélectionnez une option", // Default: "Type or select an option
            "block.dropdown.noSuggestions": "Aucune suggestion", // Default: "No Suggestions!"
            "block.shortText.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.longText.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.longText.hint":
              "<strong>Shift ⇧ + Entrer ↵</strong> pour créer un saut de ligne", // Default: "<strong>Shift ⇧ + Enter ↵</strong> to make a line break"
            "block.number.placeholder": "Votre réponse", // Default: "Type your answer here"
            "block.email.placeholder": "Votre e-mail", // Default: "Type your email here"
            "block.defaultThankYouScreen.label":
              "Votre inscription a bien été prise en compte. (il manque un peu de wording pour expliquer la suite).", // Default: "Thanks for filling this in.\n\n We will contact you soon"
            "label.hintText.key": "Touche", // Default: "Key"
            "label.progress.percent": "{{progress:percent}}% complété", // Default: "Questionnaire quotidien {{progress:percent}}% complet"
            "label.errorAlert.required": "Champs obligatoire", // Default: "This field is required!"
            "label.errorAlert.date": "Date invalide", // Default: "Invalid date!"
            "label.errorAlert.number": "Nombres uniquement", // Default: "Numbers only!"
            "label.errorAlert.selectionRequired":
              "Sélectionnez au moins une option", // Default: "Please make at least one selection!"
            "label.errorAlert.email": "E-mail invalide", // Default: "Invalid email!"
            "label.errorAlert.url": "URL invalide", // Default: "Invalid url!"
            "label.errorAlert.range":
              "Merci de choisir un nombre entre {{attribute:min}} et {{attribute:max}}", // Default: "Please enter a number between {{attribute:min}} and {{attribute:max}}"
            "label.errorAlert.minNum":
              "Merci d'entrer un nombre supérieur à {{attribute:min}}", // Default: "Please enter a number greater than {{attribute:min}}"
            "label.errorAlert.maxNum":
              "Merci d'entrer un nombre inférieur à {{attribute:max}}", // Default: "Please enter a number lower than {{attribute:max}}"
            "label.errorAlert.maxCharacters":
              "Nombre maximum de caractères atteint", // Default: "Maximum characters reached!"
            "label.submitBtn": "Je valide ma demande d'inscription", // Default: "Submit"
          },
          blocks: [
            {
              name: "group",
              id: "personaldata",
              attributes: {
                label: "Faisons connaissance",
              },
              innerBlocks: [
                {
                  id: "firstName",
                  name: "short-text",
                  attributes: {
                    label: "Prénom",
                    required: true,
                    placeholder: "Jean",
                  },
                },
                {
                  id: "lastName",
                  name: "short-text",
                  attributes: {
                    label: "Nom",
                    required: true,
                    placeholder: "Dupont",
                  },
                },
                {
                  id: "birthDate",
                  name: "date",
                  attributes: {
                    label: "Date de naissance",
                    required: true,
                    format: "JJMMAAAA",
                    separator: "/",
                  },
                },
              ],
            },
            {
              id: "phoneNumber",
              name: "short-text",
              attributes: {
                label:
                  "Sur qul numéro de téléphone souhaitez-vous être contacté ?",
                required: true,
                placeholder: "06xxxxxxxx",
              },
            }, // phone number
            {
              name: "group",
              id: "emailAndPassword",
              attributes: {
                label: "Créez votre compte Cleer",
              },
              innerBlocks: [
                {
                  id: "email",
                  name: "email",
                  attributes: {
                    label: "Email",
                    required: true,
                    placeholder: "jean@exemple.com",
                  },
                },
                {
                  id: "password",
                  name: "short-text",
                  attributes: {
                    label: "Mot de passe",
                    required: true,
                    placeholder: "********",
                  },
                },
              ],
            }, // email & password
          ],
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
          setTimeout(async () => {
            const { response, error } = await supabase.from("patients").insert({
              first_name: data.answers.firstName.value,
              last_name: data.answers.lastName.value,
              full_name: `${data.answers.firstName.value} ${data.answers.lastName.value}`,
              email: data.answers.email.value,
              phone_number: data.answers.phoneNumber.value,
              password: data.answers.password.value,
              birth_date: dayjs(
                data.answers.birthDate.value,
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD"),
              prefered_time_for_daily: 10,
              tried_therapies: [],
              partner_id: 1,
              partner_doctor_id: 1,
              cleer_doctor_id: 1,
            });
            if (error) {
              alert(
                "Un compte Cleer existe déjà avec cet email ou numéro de téléphone. Merci de contacter le support au 0767538652."
              );
              return;
            }
            setIsSubmitting(false);
            completeForm();
          }, 500);
        }}
      />
    </div>
  );
};
export default App;
